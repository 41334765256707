import { createContext } from "react";
import { useState } from "react";

import Parse from 'parse/dist/parse.min.js';

export const UserContext = createContext();

export const UserProvider = ({
    children,
}) => {

    const [currentUser, setCurrentUser] = useState(null);
    // Function that will return current user and also update current username
    const getCurrentUser = async function () {
    const currentUser = await Parse.User.current();
    // Update state variable holding current user
    setCurrentUser(currentUser);
    return currentUser;
  };

    const logInHandler = (authData) => {
    setCurrentUser(authData);
  }
  
  getCurrentUser();

    return (
        <UserContext.Provider value={{currentUser, logInHandler}}>
            {children}
        </ UserContext.Provider>
    );
}