export default function NotFound() {
  return (
    <>
    <br />
    <br />
    <br />
    <h1>Съжаляваме, няма такова съдържание :(</h1>
    {/* <h1>Съжаляваме, няма такова съдържание :(</h1>
    <h1>Съжаляваме, няма такова съдържание :(</h1>
    <h1>Съжаляваме, няма такова съдържание :(</h1>
    <h1>Съжаляваме, няма такова съдържание :(</h1> */}
    </>
  );
}
