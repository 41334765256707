import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import useFetch from '../../hooks/useFetch';

import Adventure from './Adventure';
import MyAdventure from './MyAdventure';

import styles from './Adventures.css';

export default function Adventures() {
  // AutoGoUp
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [adventures, isLoading] = useFetch(
    'https://parseapi.back4app.com/classes/vfAdventures',
    'GET',
    []
  );


  adventures.sort((a, b) => (a > b) ? 1 : -1);

  return (
    <section className='blog-page'>
      <div className='container'>
        <div className='row'>
          {isLoading ? (
            <div className='col-lg-6 col-md-12 left-side'>
              <h2>ВАШИТЕ Приключения!</h2>
              {adventures.map((c) => (
                <Adventure key={c.objectId} {...c} />
              ))}
            </div>
          ) : (
            <p>loading...</p>
          )}
          <MyAdventure />
        </div>
      </div>
    </section>
  );
}
