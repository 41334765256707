import { createContext } from "react";

import  useFetch  from "../hooks/useFetch";

export const AdventuresContext = createContext();

export const AdventuresProvider = ({
    children,
}) => {

    const [adventures] = useFetch(
        'https://parseapi.back4app.com/classes/vfAdventures', 
        'GET',
        [])

    return (
        <AdventuresContext.Provider value={{adventures}}>
            {children}
        </ AdventuresContext.Provider>
    );
}