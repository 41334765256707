import { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Parse from 'parse/dist/parse.min.js';
import { Input } from 'antd';

import styles from '../5.Auth/SupportForms.css';

import { UserContext } from "../../contexts/UserContext";

export default function Contest() {
  // AutoGoUp
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const {currentUser} = useContext(UserContext);
  const [comment, setComment] = useState('');
  const [landscape, setLandscape] = useState(false);
  const [image, setImage] = useState();
  const [errorMessage, setErrorMessage] = useState('');

  const createPicsBase = async function () {
    // This value comes from a state variable
    const newPublishersAccountName = currentUser.get('username');
    const newPublishersId = currentUser.id;
    const newImageValue = image;
    const newCommentValue = comment;
    const newLandscapeValue = landscape;
    let likesArray = [currentUser.id];
    
    if (!newImageValue) {
      setErrorMessage('Как да разберем коя снимка искаш да публикуваш, ако не я посочиш?!');
      return;
    }
    
    let parsedImg = new Parse.File("imagee", newImageValue);
    let PicsBase = new Parse.Object("PicsBase");
    PicsBase.set('publishersAccountName', newPublishersAccountName);
    PicsBase.set('publishersId', newPublishersId);
    PicsBase.set('image', parsedImg);
    PicsBase.set('Comment', newCommentValue);
    PicsBase.set('Likes', 0);
    PicsBase.set('Landscape', newLandscapeValue);
    PicsBase.set('likedUsersIds', likesArray);

    let myACL = new Parse.ACL(currentUser)
    PicsBase.setACL(myACL);
    myACL.setPublicReadAccess(true);
    myACL.setPublicWriteAccess(true);

    try {
      await PicsBase.save();
      navigate('/album');
      window.location.reload();
      return true;
    } catch (error) {
      setErrorMessage(`Грешка! ${error}`);
      return false;
    };
  };

  return (
    <div className="support">
        <div className="container">
          <h3>конкурс</h3>
          {!currentUser
  ?       <div className="row">
            <div className="col-sm-12">
              <figure className="figure">
                <img
                  src="images/contest/centralContest.jpg"
                  className="figure-img img-fluid"
                  alt="contestPict"
                />
              </figure>
            </div>
            <div className="col-sm-12">
              <p>
                Катерете, снимайте, участвайте! 
                Лесно е - направете регистрация и публикувайте вашата най-яка снимка. 
                Съберете най-много от лайковете на другите фенове и спечелете 
                <b> безплатен ден</b> в парка. 
                Всеки участник също печели <b>5% отстъпка </b> 
                от цената на всички забавления за ден в парк Ледница. 
                Задължително условие да ползвате бонуси в Парка е да сте харесали поне 2 снимки на други участници.
              </p>
              <p>
                Моля въведете Вашите <Link to="/login"><b>Име</b></Link> и 
                <Link to="/login"><b> Парола</b></Link>, за да получите достъп до пълната
                функционалност на сайта. Ако нямаш такива, не ги помниш или са ти писнали - направи нова <Link to="/register"><b>РЕГИСТРАЦИЯ</b> тук...</Link>
              </p>
            </div>

          </div>

  :       <div className="container py-5 main">
            <div className="row">
              <div className="col-md-12">
                    <p>За да участваш с твоя снимка, трябва да харесаш поне две други в 
                      <Link to="/album"> Албума</Link>. Най-яките са най-горе. Посочи кадъра, за да видиш прякора на автора, неговия коментар и броя лайкове. Цъкни за разглеждане. Успех!</p>
                    <p>Потребителят, който публикува, носи отговорност за правата върху снимката, както и за нейното съдържание. Моля придържайте се към позволеното от закона и общоприетите норми. Нека на всички ни е забавно и приятно!</p>
                    
                    {errorMessage && (<p id="errorAlert"> {errorMessage} </p>)}
                    
                    <label>Кажи нещо за емоцията на кадъра. Понякога остроумният коментар натежава в оценката ;)</label>
                      <Input
                        value={comment}
                        type="text"
                        className="form-control"
                        placeholder="Коментар"
                        onChange={(e) => setComment(e.target.value)}
                        required
                      />
                      <label>Избери най-хубавата си снимка - jpg или png - не по-голяма от 8MB.</label>
                      <Input
                        // value={image}
                        type="file"
                        className="form-control"
                        onChange={(e) => setImage(e.target.files[0])}
                        required
                      />
                      <label>Landscape - абе, нашироко да е!</label>
                      <Input
                        value={landscape}
                        type="checkbox"
                        className="form-control"
                        onChange={(e) => setLandscape(e.target.checked)}
                      />
                    
                  <button type="submit" className="btn btn-primary px-4" onClick={() => createPicsBase()}>
                    изпрати
                  </button>
              </div>
            </div>
          </div>
          }
        </div>
      </div>
  );
}
