import Parse from 'parse/dist/parse.min.js';

import { AdventuresProvider } from './contexts/AdventuresContext';
import { UserProvider } from './contexts/UserContext';
import { PicsProvider } from './contexts/PicsContext';

import Navbar from "./Components/0.Navbar/Navbar";
import Footer from "./Components/7.Footer/Footer";
import RouterComp from "./Components/0.Navbar/RouterComp";

import './App.css';

// B4A Parse initialization configuration goes here
const PARSE_APPLICATION_ID = 'XwBHamKqXHfzyCOOR2Ki1mUWSAeRUcURBBHooDGs';
const PARSE_HOST_URL = 'https://parseapi.back4app.com/';
const PARSE_JAVASCRIPT_KEY = 'cB7CTsuRCukAryeqSIULhs9UrhB42LygBIrJo0fQ';
Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY);
Parse.serverURL = PARSE_HOST_URL;

function App() {
    return (
        <UserProvider>
        <PicsProvider>
        <AdventuresProvider >
            <div>
                <Navbar/>
                <RouterComp />
                <Footer />
            </div>
        </AdventuresProvider>
        </PicsProvider>
        </UserProvider>
    );
} 

export default App;
