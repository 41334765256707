import { Routes, Route} from "react-router-dom";

import Carousel from "../1.Carousel/Carousel";
import Adventures from "../2.Adventures/Adventures";
import AdventureDetails from "../2.Adventures/AdventureDetails";
import Album from "../3.Album/Album";
import Contest from "../4.Contest/Contest";
import UserRegistration from "../5.Auth/UserRegistration";
import UserLogIn from "../5.Auth/UserLogIn";
import Coordinates from "../6.Coordinates/Coordinates";
import Admin from "../8.Admin/Admin";
import Edit from "../4.Contest/Edit";
import NotFound from "../utils/NotFound";
import CarouselItem1 from "../7.Footer/carouselItems/CarouselItem1";
import CarouselItem2 from "../7.Footer/carouselItems/CarouselItem2";
import CarouselItem3 from "../7.Footer/carouselItems/CarouselItem3";
import CarouselItem4 from "../7.Footer/carouselItems/CarouselItem4";

export default function RouterComp() {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Carousel />} />
                <Route path="/album" element={<Album />} />
                <Route path="/contest" element={<Contest />} />
                <Route path="/register" element={<UserRegistration />} />
                <Route path="/login" element={<UserLogIn />} />
                <Route path="/coordinates" element={<Coordinates />} />
                <Route path="/vlamin" element={<Admin />} />
                <Route path="/carouselItem1" element={<CarouselItem1 />} />
                <Route path="/carouselItem2" element={<CarouselItem2 />} />
                <Route path="/carouselItem3" element={<CarouselItem3 />} />
                <Route path="/carouselItem4" element={<CarouselItem4 />} />
                <Route path="/adventures" element={<Adventures />} />
                <Route path="adventures/:objectId" element={<AdventureDetails />} />
                <Route path="/edit/:picId" element={<Edit />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </div>
    );
}