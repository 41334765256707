import { useEffect, useContext, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import Parse from 'parse/dist/parse.min.js';

import AlbumCard from './AlbumCard.js';

import {PicsContext} from '../../contexts/PicsContext';

import styles from './Album.css';

export default function Album () {
    // AutoGoUp
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    const {currentUser} = useContext(UserContext);
    const {pictures} = useContext(PicsContext);
    const [errorMessage, setErrorMessage] = useState('');
    
    const orderedPubl = pictures.sort((a,b)=>Number(b.Likes) - Number(a.Likes));
  
  const updateLikes = async function (props) { 
      let likesArray = props.likedUsersIds;
    //   console.log(currentUser.id);
    //   console.log(props.likedUsersIds);
    //   console.log(likesArray.some(()=>currentUser.id));
      if (!likesArray.some((e)=>e===currentUser.id)) {
        let PicsBase = new Parse.Object('PicsBase');
        likesArray.push(currentUser.id);

        let extructedLikes = props.Likes;
        const newLikes = extructedLikes+1;
        PicsBase.set('objectId', props.objectId);
        PicsBase.set('Likes', newLikes);
        PicsBase.set('likedUsersIds', likesArray);
        try {
            await PicsBase.save();
            // alert('Чудесен избор!');
            window.location.reload();
            return true;
        } catch (error) {
            // Error can be caused by lack of Internet connection
            setErrorMessage(`Грешка! ${error}`);
            //   alert(`Error! ${error.message}`);
            return false;
        }
      } else if (currentUser.id===props.publishersId){
        setErrorMessage('Нека други оценят твоята снимка;)');
        window.scrollTo(0, 0);
      } else {
        setErrorMessage('Вече я харесваш тази:)');
        window.scrollTo(0, 0);
      }
}
  
    return (
        <section id="portfolio">
                    {/* <h2></h2>
                    <a target="blank" href="https://photos.google.com/share/AF1QipMOuggnmwjt7jmhH--oQrF-qIzzGIBL8bxUzy_WO8PIrQ0Jhju__03aj3y_BbGI8Q?pli=1&key=M3hxVlhrSHdrQm9VMl9rS1F3bDFHVk80V080cDFn"><h3><b>Албум в GOOGLE</b></h3></a> */}
          <div className="support">
                <div className="container">
                    <h3>Албум</h3>

                    <div className="row justify-content-center">
                        <div className="col-md-12 col-12">
                            
                            {errorMessage && (<h5 id="errorAlert"> {errorMessage} </h5>)}

                            <div className="row">
                                {orderedPubl.map(c => <AlbumCard key={c.objectId} {...c}  onClick={updateLikes}/>)}
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </section>
    );
}