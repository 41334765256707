import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import useFetch from '../../hooks/useFetch';

import AdventureEdit from './AdventureEdit';

//import styles from './Admin.css';

export default function Adventures() {
  // AutoGoUp
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [adventures, isLoading] = useFetch(
    'https://parseapi.back4app.com/classes/vfAdventures',
    'GET',
    []
  );


  adventures.sort((a, b) => (a.OrderingNumber > b.OrderingNumber) ? 1 : -1)


  return (
    //<section className='blog-page'>
      //<div className='container'>
        <div className='row'>
          {isLoading ? (
            <div className='col-lg-6 col-md-12 left-side'>
              <br></br>
              <br></br>
              <br></br>
              <h1>АДМИН модул!</h1>
              <h3>Изберете ВАШИТЕ Приключения за промяна!</h3>
              {adventures.map((c) => (
                <AdventureEdit key={c.objectId} {...c} />
              ))}
            </div>
          ) : (
            <p>loading...</p>
          )}
        </div>
      //</div>
    //</section>
  );
}
