import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";

import Parse from 'parse/dist/parse.min.js';
import { Input } from 'antd';

import './SupportForms.css';

export default function UserRegistration(e) {
 // AutoGoUp
 useEffect(() => {
  window.scrollTo(0, 0);
}, []);

  const navigate = useNavigate();

  const {currentUser} = useContext(UserContext);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repass, setRepass] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const doUserRegistration = async function () {
    // Note that these values come from state variables that we've declared before
    const usernameValue = username;
    const passwordValue = password;
    const emailValue = email;

    if (password.length<6) {
      // throw (alert('Дай поне 6 символа за парола;)'))
      setErrorMessage('Дай поне 6 символа за парола;)');
    }else if (password !== repass) {
      setErrorMessage('Моля потвърдете паролата! Въведеното в двете полета трябва да съвпада.');
      // throw (alert('Моля потвърдете паролата! Въведеното в двете полета трябва да съвпада.'))
    }else if(email.length===0){
      setErrorMessage('Без валиден мейл няма как да информираме печелившия!');
    } else {
    try {
      const createdUser = await Parse.User.signUp(usernameValue, passwordValue);
      navigate('/contest');
      window.location.reload();
      // alert(`Браво! Потребител ${createdUser.getUsername()} беше успешно създаден!`);
      
        let MailsBase = new Parse.Object("MailsBase");
        MailsBase.set('email', emailValue);
        MailsBase.set('username', usernameValue);

        let myACL = new Parse.ACL(currentUser)
        MailsBase.setACL(myACL);
    
        try {
          await MailsBase.save();
        // alert('Браво! Качихте страхотна снимка!');
        return true;
        } catch (error) {
          setErrorMessage(`Грешка! ${error}`);
          // alert(`Error! ${error.message}`);
          return false;
        };

      return true;
      } catch (error) {
      // signUp can fail if any parameter is blank or failed an uniqueness check on the server
      
      if (error == 'ParseError: 202 Account already exists for this username.') {
        setErrorMessage('Имаме вече Приключенец с такъв псевдоним!');
        // alert('Имаме вече Приключенец с такъв псевдоним!');
        } else if (error == 'ParseError: -1 Cannot sign up user with an empty password.') {
          setErrorMessage('Няма как без парола...');
          // alert('Няма как без парола...');
        }else if (error == 'ParseError: -1 Cannot sign up user with an empty username.') {
          setErrorMessage('Сам трябва да си измислиш псевдонима...');
        // alert('Сам трябва да си измислиш псевдонима...');
        }
       else {
        setErrorMessage(`Грешка! ${error}`);
        // alert(`Грешка! ${error}`);
        }
        return false;
    }}
  }
    
return(
<>
{/* Blg-Section */}
<div className="support">
  <div className="container">
   <h3>регистрация</h3>
    <div className="row">
        <div className="col-sm-12">
        </div>
        <div className="col-sm-12">
             <p>Моля попълнете формата за регистрация, за да получите достъп до пълната функционалност на сайта. 
              Поне 6 символа за парола! След успешно завършване на регистрацията ще бъдете включени автоматично с вашия нов акаунт.
               Ако вече имаш регистрация и си я помниш, влизай <Link to="/login">оттук...</Link> </p>
        </div>
    </div>

     <div className="container py-5 main">
      <div className="row">
          <div className="col-md-12">
          {errorMessage && (<p id="errorAlert"> {errorMessage} </p>)}
              {/* <form> */}
                  <div className="form-group row">
                      <div className="col-sm-6">
                          <Input 
                            value={username}
                            type="text" 
                            className="form-control" 
                            placeholder="Име / Прякор" 
                            onChange={(e) => setUsername(e.target.value)}
                             required
                            />
                      </div>
                      <div className="col-sm-6">
                          <Input 
                            value={email}
                            type="email" 
                            className="form-control" 
                            placeholder="Мейл" 
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            />
                      </div>
                      <div className="col-sm-6">
                          <Input 
                            value={password}
                            type="password" 
                            className="form-control" 
                            placeholder="Парола" 
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            />
                      </div>
                      <div className="col-sm-6">
                        <input 
                          type="password"
                          name='repass'
                          className="form-control" 
                          placeholder="Същата парола"  
                          onChange={(e) => setRepass(e.target.value)}
                          required
                          />
                      </div>
                      {/* <div className="col-sm-6">
                          <input type="text" className="form-control" placeholder="Населено място"  />
                      </div>
                      <div className="col-sm-6">
                          <input type="number" className="form-control" placeholder="Години"  />
                      </div> */}
                  </div>
                  <button 
                      onClick={() => doUserRegistration()} 
                      type="submit" 
                      className="btn btn-primary px-4"
                    >
                      Регистрирай
                    </button>
              {/* </form> */}
          </div>
      </div>
     </div>
  </div>
</div>
</>
)};

