import { Link } from "react-router-dom";

import styles from './Adventures.css';

export default function Adventure({
  img,
  alt,
  title,
  descr,
  objectId,
}) {
  return (
    <div className="row">
      <div className="col-md-6">
        <figure className="figure">
          <Link to={`/adventures/:${objectId}`}>
          <img
            src={img.url}
            className="figure-img img-fluid"
            alt={alt}
          />
          </Link>
        </figure>
      </div>
      <div className="col-md-6">
            <Link title={title} to={`/adventures/:${objectId}`}>
              <h4>{title}</h4>
          </Link>
          <p>{descr}</p>
      </div>
    </div>
  );
}
