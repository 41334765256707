import { useEffect, useContext, useState } from "react";
import Parse from 'parse/dist/parse.min.js';
import { Link, NavLink } from "react-router-dom";

import styles from "./Navbar.css";

import { UserContext } from "../../contexts/UserContext";

export default function Navbar() {
  const setNavStyle = ({isActive}) => {
    return isActive
    ? styles['active']
    :undefined;
  }

  const {currentUser, logInHandler} = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState('');

  // AutoGoUp
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openMobileBridge = 'false';

  const toCollapsBridge=() => {
    openMobileBridge = 'true';
  }
 
  const doUserLogOut = async function () {
    try {
      await Parse.User.logOut();
      // To verify that current user is now empty, currentAsync can be used
      const currentUser = await Parse.User.current();
      if (currentUser === null) {
        // alert('До нови срещи!');
      }
      // Update state variable holding current user
      getCurrentUser();
      return true;
    } catch (error) {
      setErrorMessage(`Грешка! ${error}`);
      // alert(`Error! ${error.message}`);
      return false;
    }
  };

  // Function that will return current user and also update current username
  const getCurrentUser = async function () {
    const currentUser = await Parse.User.current();
    // Update state variable holding current user
    logInHandler(currentUser);
    return currentUser;
  };
  
  return (
    <nav className="navbar navbar-expand-lg navbar-dark cyan fixed-top">
      <div className="container">
        
        <Link className="navbar-brand" to="/">
            <img src="images/logos/scrL.png" alt="nav-logo"/>
          {/* <div className="logo">
            <img src="images/logos/scrL.png" alt="nav-logo"/>
          </div> */}
        </Link>

        {currentUser
          && <h6 className="navbar-brand">
              {`Здравей, ${currentUser.get('username')}!`}
              <span className="sr-only">(current)</span>
              </h6>
        }

        <button className="navbar-toggler" type="button" 
                data-toggle="collapse" data-target="#navbarSupportedContent-4" 
                aria-controls="navbarSupportedContent-4" 
                aria-expanded={openMobileBridge} 
                aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

        {currentUser
          ?<div className="collapse navbar-collapse" id="navbarSupportedContent-4">
          <ul className="navbar-nav ml-auto">
          <li className="nav-item">
              
            </li>
            <li className="nav-item">
              <NavLink className="nav-link setNavStyle" to="/" onClick={toCollapsBridge}>
                Ледницата
                <span className="sr-only">(current)</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link setNavStyle" to="/adventures" onClick={toCollapsBridge}>
                Приключения
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link setNavStyle" to="/album" onClick={toCollapsBridge}>
                Албум
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link setNavStyle" to="/contest" onClick={toCollapsBridge}>
                Конкурс
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link setNavStyle" to="/" onClick={() => {doUserLogOut(); toCollapsBridge()}}>
                Изход
              </NavLink>
              {errorMessage && (<h5 id="errorAlert"> {errorMessage} </h5>)}
            </li>
            <li className="nav-item">
              <NavLink className="nav-link setNavStyle" to="/coordinates" onClick={toCollapsBridge}>
                Координати
              </NavLink>
            </li>
          </ul>
                </div>
              : <div className="collapse navbar-collapse" id="navbarSupportedContent-4">
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <NavLink className="nav-link setNavStyle" to="/" onClick={toCollapsBridge}>
            Ледницата
            <span className="sr-only">(current)</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link setNavStyle" to="/adventures" onClick={toCollapsBridge}>
            Приключения
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link setNavStyle" to="/album" onClick={toCollapsBridge}>
            Албум
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link setNavStyle" to="/contest" onClick={toCollapsBridge}>
            Конкурс
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link setNavStyle" to="/login" onClick={toCollapsBridge}>
            Вход
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link setNavStyle" to="/coordinates" onClick={toCollapsBridge}>
            Координати
          </NavLink>
        </li>
      </ul>
            </div>
        }
      </div>
    </nav>
  );
}
