import { useEffect, useState } from "react";

export default function useFetch (url, method, defaultValue) {
    const [data, setData] = useState(defaultValue);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        fetch(url, {
            method: method,
            headers: {
                'X-Parse-Application-Id': 'XwBHamKqXHfzyCOOR2Ki1mUWSAeRUcURBBHooDGs',
                'X-Parse-REST-API-Key': 'kwUKzkv4QNkVX3miI74lUPTw1Qzu1j4UZIi2Qql9',
            }
        })
        .then(res=>res.json())
        .then(result=>{
            setData(result.results);
            setIsLoading(false);
        });
    }, [url]);
    return [data, setData, isLoading];
}