import styles from './Adventures.css';

export default function MyAdventure() {
  return (
          <div className="col-lg-6 col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="right-side">
                  <h3>... и моето ПРИКЛЮЧЕНИЕ</h3>
                  <figure>
                    <img id="jorj" src="images/Jorj.jpg" alt="JorjPicture" style={{height: 'unset'}} />
                  </figure>
                  <h5>
                    <p>Започвайки да пиша за това специално място ми се иска всяка дума и определение за него да бъде точно такова каквото трябва да бъде, нито повече, нито по-малко. Мога да кажа много, но започвайки всяко изречение, в представите ми всеки път идва една и съща картина - на хората минали и слизащи от виаферата.</p>
                    <p>Иска ми се да опиша как точно очите им говорят в този момент, когато се оказва някак трудно думите да се оформят... Иска ми се да опиша как въпреки, че всеки мускул по тялото трепери , не изглежда да изпитват съжаление дори за секунда... даже напротив... гледат изминалия назад път с възхищение с някаква интересна почит..</p>
                    <p>И.. за Viaferra Ледницата..</p>
                    <p>Местоположение - село Брестница ..още от тук започва магията. Село Брестница е известно с множеството пещери в района, най-известната от които е облагородената пещера "Съева дупка" привличаща по всяка време множество туристи. Село Брестница и околността предлага много приятни маршрути за разходка включващи и еко пътека за тези, за които няколко часа сред природата означават истинска почивка.</p>
                    <p>От скоро в този район се появи "Viaferra Ледницата"... Звучи почти страховито, но спокойно - Ледницата проявява истинската си ледена същност само през зимата, когато 100 метровата скала се покрива с лед. Основната част на виаферата е изградена именно на тази скала - парапети.. въжета..мостове..стълби. Всичко това са елементи от Viaferrata, но тя като цяло предствлява система от стоманени въжета, по която осигурявайки се (задължително!!! с екипировка, която може да се наеме на място...или ако имате ваша собствена) преминавате по въжен мост над 30 метрова пропаст, интимничите със скалата, захващайки се за парапети и стъпала и почивате за малко на дървена площадка, изградена специално за тази цел в основата на едно дърво...и продължавате напред за да усетите колко сила и енергия можете да вложите още в приключението си..</p>
                    <p>Имате възможност де изберете няколко маршрута с различна степен на трудност, всеки от които обаче дава възможност на адреналина да се покачи и да ви доведе до усещането, че на моменти изпадате в безтегловност. Усещането е невероятно и поради факта, че Viafera Ледницата е единствената по рода си в България. Любопитството и тръпката обикновенно надделяват ..</p>
                    <p>И като че ли не остава място за съжаление...:)</p>
                    <p><i>Написано от Вяра Баракова</i></p>
                  </h5>
                </div>
              </div>
            </div>
          </div>
  )}
