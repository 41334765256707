import { createContext } from "react";

import  useFetch  from "../hooks/useFetch";

export const PicsContext = createContext();

export const PicsProvider = ({
    children,
}) => {

    const [pictures] = useFetch(
        'https://parseapi.back4app.com/classes/PicsBase', 
        'GET',
        [])

    return (
        <PicsContext.Provider value={{pictures}}>
            {children}
        </ PicsContext.Provider>
    );
}