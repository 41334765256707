import { useEffect } from "react";

import styles from "./Coordinates.css";

export default function Coordinates() {
  
  //AutoGoUp
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //<h2 className="red">Внимание! Приключенската част на парка няма да работи от 22-ри до 29-ти септември включително.</h2>
  return (
    <div className="support">
      <div className="container">
        <a
          target="blank"
          href="https://www.google.com/maps/place/%D0%9B%D0%B5%D0%B4%D0%BD%D0%B8%D1%86%D0%B0%D1%82%D0%B0/@43.047662,24.1814468,17z/data=!3m1!4b1!4m5!3m4!1s0x40abb41d5400054d:0x897d4706163a63!8m2!3d43.0476581!4d24.1836355"
        >
          Зареди на Google Карти
        </a>
        <h4 className="yellow">43.04771, 24.18350</h4>
        <h3>адрес</h3>
        <h5>
          Паркинг на пещера "Съева Дупка", 5761 Брестница. Тъй като пътят
          няма директна връзка с магистралата, ако желаете да ни посетите, е
          необходимо да се отклоните през с. Брестница.
        </h5>
        <br></br>
        <h5>За контакт - Жорж Влайков - 088 744 5830.</h5>
        <h5><span>&#9993;</span> - <a href="mailto:viaferrataltd@gmail.com">viaferrataltd@gmail.com</a>; <a href="mailto:lednicaltd@gmail.com">lednicaltd@gmail.com</a></h5>
        <h5>За контакт с руски език - Снежана Йотова - 089 789 1848.</h5>
        <div className="row">
          <div className="col-sm-12">
            <figure className="figure">
              <img
                src="/images/GoogleMap.png"
                className="figure-img img-fluid"
                alt="googleMapsPict"
              />
            </figure>
          </div>
          <div className="col-sm-12">
            <section className="wd">
              <h4>Работно време</h4>
              <div>сряда - Отворено 24 часа;</div>
              <div>четвъртък - Отворено 24 часа;</div>
              <div>петък - Отворено 24 часа;</div>
              <div>събота - Отворено 24 часа;</div>
              <div>неделя - 0:00 ч. до 20:00 ч.</div>
              <div>понеделник и вторник - почивка;</div>
            </section>
          </div>
          
        </div>
      </div>
    </div>
  );
}
