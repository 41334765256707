import { useState, useContext, useEffect } from "react";
import { UserContext } from "../../contexts/UserContext";
import { Link } from "react-router-dom";

import styles from './Album.css';

export default function AlbumCard(props)
{
  const {currentUser} = useContext(UserContext);
  const [isLiked, setIsLiked] = useState(false);


const updateHand = async function (props) { 
    let likesArray = props.likedUsersIds;
    // console.log(currentUser.id);
    // console.log(props.likedUsersIds);
    // console.log(likesArray.some(()=>currentUser.id));
    if (!likesArray.some((e)=>e===currentUser.id)) {
        setIsLiked('true');  
    }
}
        
useEffect(() => {
    updateHand(props);
}, []);

return(
    <>
        <a href={props.image.url} 
        data-toggle="lightbox" 
        data-gallery="example-gallery" 
        className={!props.Landscape? "col-xl-3 col-md-4 box-2" : "col-xl-6 col-md-4 box-1"}>
             
                <img src={props.image.url} className="img-fluid" />
                <div className="overlay">
                    <img src="images/comment.png" alt="plus-icon" />
                    <div className="text">{props.Comment}<span>{`автор - ${props.publishersAccountName}`}</span></div>
                    <div className="count">{props.Likes}</div>
                </div>
        </a>
            {currentUser &&
            <div className="picsBtns">
                    <button className='likeBtn' onClick={() => {props.onClick(props);}}>
                        {!isLiked
                            ?<i className="fa-solid fa-thumbs-up"></i>
                            :<i className="fa-regular fa-thumbs-up"></i>
                        }
                    </button>
                    {props.publishersId===currentUser.id &&
                        <Link to={`/edit/:${props.objectId}`}>
                        <button className='likeBtn'>
                            <i className="fa-solid fa-gear"></i>
                        </button>
                        </Link>}
            </div>
            }
        </>
    )
}