import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";

import Parse from 'parse/dist/parse.min.js';
import { Input } from 'antd';

import './SupportForms.css';

export default function UserLogIn() {
  const navigate = useNavigate();
   const [username, setUsername] = useState('');
   const [password, setPassword] = useState('');
   const [currentUser, setCurrentUser] = useState(null);
   const [errorMessage, setErrorMessage] = useState('');
 
   const doUserLogIn = async function () {
     // Note that these values come from state variables that we've declared before
     const usernameValue = username;
     const passwordValue = password;
     try {
       const loggedInUser = await Parse.User.logIn(usernameValue, passwordValue);
       navigate('/album');
       window.location.reload();
       // logIn returns the corresponding ParseUser object
      //  alert(
      //    `Добре дошъл, ${loggedInUser.get('username')}! Щастливи сме, че си пак с нас :)`
      //  );
       
      // To verify that this is in fact the current user, `current` can be used
       const currentUser = await Parse.User.current();
       console.log(loggedInUser === currentUser);
       // Clear input fields
       setUsername('');
       setPassword('');
       // Update state variable holding current user
       getCurrentUser();
       return true;
     } catch (error) {
       // Error can be caused by wrong parameters or lack of Internet connection
        if (error == 'ParseError: 200 username/email is required.') {
          setErrorMessage('А кой си ти и имаш ли парола?!');
          // alert('А кой си ти и имаш ли парола?');
        } else if (error == 'ParseError: 201 password is required.') {
          setErrorMessage('Няма как без парола...');
        // alert('Няма как без парола...');
        }else if (error == 'ParseError: 101 Invalid username/password.') {
          setErrorMessage('Грешна парола или псевдоним!');
          // alert('Грешна парола или псевдоним!');
          }
       else {
        setErrorMessage(`Грешка! ${error}`);
        // alert(`Грешка! ${error}`);
        }
       return false;
     }
   };

    // Function that will return current user and also update current username
   const getCurrentUser = async function () {
     const currentUser = await Parse.User.current();
     // Update state variable holding current user
     setCurrentUser(currentUser);
     return currentUser;
   };
  
  return(
  <div className="support">
  {/* Blog-Section */}
  <div className="container">
   <h3>Вход</h3>
    <div className="row">
        <div className="col-sm-12">
           <figure className="figure">
             <img src="images/support-bg.jpg" className="figure-img img-fluid" alt="A generic square placeholder image with rounded corners in a figure." />
           </figure>
        </div>
        <div className="col-sm-12">
             <p> Въведи тук <b>Име</b> и <b>Парола</b>, за да получиш достъп до пълната функционалност на сайта. Ако нямате регистрация, може да направите <b><Link to="/register">тук - РЕГИСТРАЦИЯ</Link></b></p>
        </div>
    </div>
    {currentUser === null && (
     <div className="container py-5 main">
      <div className="row">
          <div className="col-md-12">
          {errorMessage && (<p id="errorAlert"> {errorMessage} </p>)}
              {/* <form> */}
                  <div className="form-group row">
                      <div className="col-sm-6">
                          <Input 
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            type="text" 
                            className="form-control" 
                            // name="username" 
                            placeholder="Име" 
                            required 
                          />
                      </div>
                          <div className="col-sm-6">
                          <Input 
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type="password" 
                            className="form-control" 
                            name="password" 
                            placeholder="Парола" 
                            required 
                        />
                      </div>
                  </div>
                  <button 
                    type="submit" 
                    className="btn btn-primary px-4" 
                    onClick={() => doUserLogIn()}
                  >
                    вход
                  </button>
              {/* </form> */}
          </div>
      </div>
     </div>
    )} 
  </div>
</div>
)}

