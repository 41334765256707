import { useEffect, useContext } from "react";
import { useParams, Navigate } from "react-router-dom";

import {AdventuresContext} from "../../contexts/AdventuresContext";

import styles from './Adventures.css';

export default function AdventureDetails() {
  // AutoGoUp
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {adventures} = useContext(AdventuresContext);
  const params = useParams();
  const removedDots = params.objectId.replace(':', '');
  const detailed = adventures.filter(a=>a.objectId==removedDots);
  //detailed = adventures.sort((a, b) => (a > b) ? 1 : -1);

  return (
    
    <section className="blog-page">
      {detailed[0].pic1
      ?<div className="container">
         <div className="row">
         
          <div className="col-lg-6 col-md-12 left-side">
            <div className="row">
                    <img
                      src={detailed[0].img.url}
                      className="figure-img img-fluid"
                    />
            </div>
            <div className="row">
                    <img
                      src={detailed[0].pic1.url}
                      className="figure-img img-fluid"
                    />
            </div>
            <div className="row">
                    <img
                      src={detailed[0].pic2.url}
                      className="figure-img img-fluid"
                    />
            </div>
            <div className="row">
                    <img
                      src={detailed[0].pic3.url}
                      className="figure-img img-fluid"
                    />
            </div>
            <div className="row">
                    <img
                      src={detailed[0].pic4.url}
                      className="figure-img img-fluid"
                    />
            </div>
            <div className="row">
                    <img
                      src={detailed[0].pic5.url}
                      className="figure-img img-fluid"
                    />
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="right-side">
                  <h3>{detailed[0].title}</h3>
                  <h5>
                    {detailed[0].bigDescrArr.map((para)=>(
                      <p>{para}</p>
                    ))}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       :<Navigate to="/notFound" replace={true} />}
    </section>
  );
}
