import { useEffect, useContext, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import {PicsContext} from "../../contexts/PicsContext";

import Parse from 'parse/dist/parse.min.js';
import { Input } from 'antd';

import styles from '../5.Auth/SupportForms.css';

export default function Contest() {
  // AutoGoUp
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const {pictures} = useContext(PicsContext);

  const params = useParams();
  const removedDots = params.picId.replace(':', '');
  const detailed = pictures.filter(p=>p.objectId==removedDots);
  
  const [comment, setComment] = useState('');
  const [landscape, setLandscape] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const updatePicsBase = async function (removedDots) {
    const newCommentValue = comment;
    const newLandscapeValue = landscape;
    
    let PicsBase = new Parse.Object('PicsBase');
    PicsBase.set('objectId', removedDots);
    PicsBase.set('Comment', newCommentValue);
    PicsBase.set('Landscape', newLandscapeValue);
    try {
      await PicsBase.save();
      navigate('/album');
      window.location.reload();
      return true;
    } catch (error) {
      // Error can be caused by lack of Internet connection
      setErrorMessage(`Грешка! ${error}`);
      return false;
    };
  };
  
const delPicsBase = async function (removedDots) {
  let PicsBase = new Parse.Object('PicsBase');
  PicsBase.set('objectId', removedDots);
  try {
    await PicsBase.destroy();
    navigate('/album');
    window.location.reload();
    return true;
  } catch (error) {
    // Error can be caused by lack of Internet connection
    setErrorMessage(`Грешка! ${error}`);
    return false;
  };
};

    return (
    <div className="support">
        <div className="container">
          <h2>промяна на публикация</h2>
          
         <div className="container py-5 main">
            <div className="row">
              <div className="col-md-12">
                    
                    <p>Потребителят, който публикува снимката, носи отговорност за правата върху нея, както и за съдържанието й. Моля придържайте се към позволеното от закона и общоприетите норми. Нека на всички ни е забавно и приятно!</p>
                    <label>Промени коментара.</label>
                      <Input
                        value={comment}
                        type="text"
                        className="form-control"
                        placeholder={detailed[0].Comment}
                        onChange={(e) => setComment(e.target.value)}
                      />
                      
                      <img src={detailed[0].image.url} className="img-fluid"/>
                      
                      <label>Landscape - абе, нашироко да е!</label>
                      <Input
                        value={landscape}
                        type="checkbox"
                        className="form-control"
                        onChange={(e) => setLandscape(e.target.checked)}
                        />
                    
                  <button type="submit" className="btn btn-primary px-4" onClick={() => updatePicsBase(removedDots)}>
                    коригирай
                  </button>
              </div>
            </div>
          </div>

          {errorMessage && (<p id="errorAlert"> {errorMessage} </p>)}

          <button type="submit" className="btn btn-primary px-4"
                    onClick={() => { 
                      if (window.confirm('Сигурен ли си, че искаш да я махнем? Ще изгубиш всичките си лайкове :(')) delPicsBase(removedDots).onCancel() } } 
                  >
                      изтрий снимката
          </button>
        </div>
      </div>
  );
}

